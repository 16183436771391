<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="8" class="d-flex">
          <img
            src="@/assets/icons/ocr.png"
            height="22"
            width="22"
            alt="age-icon"
          />
          <div class="main-heading">
            Document Verification
            <v-icon class="info-icon" v-if="!alert" @click="alert = true"
              >mdi-information-outline</v-icon
            >
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-alert v-model="alert" close-text="Close Alert" color="age-alert">
            <template v-slot:append>
              <img
                @click="alert = false"
                src="@/assets/icons/close-icon.svg"
                height="22"
                width="22"
                class="cursor-pointer"
                alt="close-icon"
              />
            </template>
            Empowering Global Connectivity through State-of-the-Art OCR
            Solutions: With our expansive reach spanning continents, from
            bustling metropolises to remote villages, we revolutionize document
            digitization. Seamlessly integrating OCR technology worldwide, we
            enhance accessibility and streamline operations for businesses of
            all scales.
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="isLoading">
        <v-col cols="12" class="no-record">
          <v-progress-circular
            :width="3"
            color="#173dd0"
            indeterminate
          ></v-progress-circular>
          <span class="pl-2"> Loading...</span>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-if="jsonData" cols="12">
          <div class="result-btns float-right">
            <v-btn @click="retry" depressed class="try-more">
              <span class="px-1">Try More</span>
              <img
                src="@/assets/icons/retry-icon.svg"
                height="12"
                width="12"
                alt="next-arrow"
              />
            </v-btn>
          </div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 5">
          <p class="sub-heading">Provide Details</p>
          <div>
            <p class="file-limit-text mb-0">
              <span class="fw-500">Allowed Document Type:</span>
              ID Card, Passport, Driving License, Credit/Debit Card
            </p>
          </div>
          <div class="d-flex justify-center ma-5">
            <v-card
              class="age-verification-card"
              width="640"
              style="height: 100%"
            >
              <div class="step-retry">
                <p
                  v-if="imageSrc && showRetry"
                  class="card-retry cursor-pointer"
                  @click="retryImage(false)"
                >
                  Retry
                  <img
                    src="@/assets/icons/retry-blue.svg"
                    height="12"
                    width="12"
                    alt="retry"
                    class="ml-1"
                  />
                </p>
              </div>
              <v-card-title class="card-title">
                Document Front Side <span style="color: red">*</span>
              </v-card-title>
              <v-card-text
                class="card-body"
                :class="
                  $vuetify.breakpoint.smAndDown && imageSrc
                    ? 'card-body-mobile'
                    : ''
                "
              >
                <div v-if="!imageSrc" class="selfie-upload">
                  <div class="option">
                    <div class="option-child" @click="takeSelfie(false)">
                      <img
                        src="@/assets/icons/camera-icon.svg"
                        height="20"
                        width="20"
                        alt="camera-icon"
                      />
                      <span class="option-text hand">Capture Document</span>
                    </div>
                    <v-divider class="vertical-divider" vertical></v-divider>
                    <div class="option-child" @click="browseClick()">
                      <img
                        src="@/assets/icons/file-upload.svg"
                        height="20"
                        width="20"
                        alt="file-upload-icon"
                      />
                      <div class="option-text hand">Upload Document</div>
                    </div>
                  </div>
                  <div cols="6" class="file-limit-text">Maximum size 3 MB</div>
                </div>
                <v-row v-if="imageSrc" style="width: 100%">
                  <v-col cols="12" class="pa-0">
                    <img
                      class="preview-img"
                      :class="
                        $vuetify.breakpoint.xsOnly ? 'preview-img-mobile' : ''
                      "
                      id="image-preview"
                      :src="imageSrc"
                      alt=""
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="d-flex justify-center ma-5">
            <v-card
              class="age-verification-card"
              width="640"
              style="height: 100%"
            >
              <div class="step-retry">
                <p
                  v-if="backImageSrc && showRetry"
                  class="card-retry cursor-pointer"
                  @click="retryImage(true)"
                >
                  Retry
                  <img
                    src="@/assets/icons/retry-blue.svg"
                    height="12"
                    width="12"
                    alt="retry"
                    class="ml-1"
                  />
                </p>
              </div>
              <v-card-title class="card-title">
                Document Back Side
              </v-card-title>
              <v-card-text
                class="card-body"
                :class="
                  $vuetify.breakpoint.smAndDown && imageSrc
                    ? 'card-body-mobile'
                    : ''
                "
              >
                <div v-if="!backImageSrc" class="selfie-upload">
                  <div class="option">
                    <div class="option-child" @click="takeSelfie(true)">
                      <img
                        src="@/assets/icons/camera-icon.svg"
                        height="20"
                        width="20"
                        alt="camera-icon"
                      />
                      <span class="option-text hand">Capture Document</span>
                    </div>
                    <v-divider class="vertical-divider" vertical></v-divider>
                    <div class="option-child" @click="browseClick(true)">
                      <img
                        src="@/assets/icons/file-upload.svg"
                        height="20"
                        width="20"
                        alt="file-upload-icon"
                      />
                      <div class="option-text hand">Upload Document</div>
                    </div>
                  </div>
                  <div cols="6" class="file-limit-text">Maximum size 3 MB</div>
                </div>
                <v-row v-if="backImageSrc" style="width: 100%">
                  <v-col cols="12" class="pa-0">
                    <img
                      class="preview-img"
                      :class="
                        $vuetify.breakpoint.xsOnly ? 'preview-img-mobile' : ''
                      "
                      id="image-preview"
                      :src="backImageSrc"
                      alt=""
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <input
                ref="back-file-uploader"
                id="back-upload-file"
                class="d-none"
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                @change="onFileChanged($event, true)"
              />
            </v-card>
          </div>
          <div v-if="hideSubmit" class="result-btns button-section mx-5">
            <!-- <p class="font-13 fw-600 red--text">
              <span v-if="remainingCount == 0"
                >Verification Limit Exceeded</span
              >
            </p> -->
            <v-btn
              depressed
              class="try-more"
              :disabled="validatePayload"
              @click="submitData"
              :loading="isProcessing"
              ><span class="px-1">Submit</span></v-btn
            >
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          cols="1"
          class="text-center"
        >
          <v-divider vertical class="py-5"></v-divider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
          <p class="sub-heading no-padding">OCR Result</p>

          <div class="text-left" v-if="apiCallMade">
            <!-- Conditional message for OCR results -->
            <p
              v-if="getResultOcr === 'verification_accepted'"
              class="green--text"
            >
              <span class="black--text">Verification Status:</span>
              Accepted
            </p>
            <p
              v-else-if="getResultOcr === 'verification_declined'"
              class="red--text"
            >
              <span class="black--text">Verification Status:</span>
              Declined
            </p>
          </div>
          <div class="text-left" v-if="apiCallMade">
            <!-- Conditional message for OCR results -->
            <p
              v-if="getResultOcr === 'verification_declined'"
              class="green--text"
            >
              <span class="black--text">Decline Reason: </span>
              <span class="black--text">{{ getDeclineReason }}</span>
            </p>
          </div>
          <v-card class="ocr-result-card">
            <div v-if="jsonData">
              <json-tree :data="jsonData"></json-tree>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-bind="attrs"
                    v-on="on"
                    @click="copyToClipboard(jsonData)"
                    class="copy-icon"
                    src="@/assets/icons/copy.png"
                    width="20"
                  ></v-img>
                </template>
                <span>{{ messageCopy }}</span>
              </v-tooltip>
            </div>
            <div v-if="jsonData == null && resultLoader" class="loader-div">
              <span class="loader">
                <v-progress-circular :size="60" color="primary" indeterminate>
                </v-progress-circular>
                Fetching Results...</span
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <Camera
        v-if="startCamera"
        @attached-image="getImage"
        @close-camera-dialog="closeCamera"
      />
      <input
        ref="file-uploader"
        id="upload-file"
        class="d-none"
        type="file"
        accept="image/jpeg,image/JPEG,image/jpg,image/JPG,image/PNG,image/png"
        @change="onFileChanged($event)"
      />
    </v-container>
  </div>
</template>

<script>
import Camera from "../common/Camera.vue";
import ApiService from "../../services/Api";
import {
  showErrorMessage,
  showSimpleErrorMessage,
} from "../../utils/showError";
import { API_URL } from "@/utils/env.js";
import countries from "../../constants/countries.json";
import JsonTree from "vue-json-tree";
import { mapGetters } from "vuex";
export default {
  name: "DocumentOCR",
  components: { Camera, JsonTree },
  data() {
    return {
      startCamera: false,
      isProcessing: false,
      isLoading: false,
      showRetry: false,
      searchResultInterval: false,
      setTimeoutInterval: null,
      baseUrl: API_URL,
      clientSecret: null,
      alert: true,
      imageSrc: null,
      backImageSrc: null,
      fileSizeLimit: 3 * 1024 * 1024,
      referenceId: null,
      disabledBtn: true,
      country: null,
      document: null,
      documentTypes: [
        { text: "ID Card", value: "id_card" },
        { text: "Passport", value: "passport" },
        { text: "Driving License", value: "driving_license" },
      ],
      messageCopy: "Click to Copy",
      jsonData: null,
      resultLoader: false,
      hideSubmit: true,
      // remainingCount: 0,
      apiCallMade: false,
      isBackSide: false,
    };
  },
  computed: {
    ...mapGetters(["getResultOcr", "getDeclineReason"]),
    getCountriesList() {
      return countries;
    },
    validatePayload() {
      if (this.imageSrc != null) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getClientSecret();
  },
  methods: {
    /**
     * @description Copies the provided string to the clipboard
     * @param {string} key - The string to be copied
     */
    copyToClipboard(key) {
      // Function to copy JSON data to clipboard
      const jsonString = JSON.stringify(key, null, 2);

      // Copy JSON string to clipboard
      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          this.messageCopy = "Copied!";
          // Optionally, you can show a success message
        })
        .catch((err) => {
          console.error("Failed to copy JSON to clipboard:", err);
          // Optionally, you can show an error message
        });
      setTimeout(() => {
        this.messageCopy = "Click to Copy";
      }, 1000);
    },

    /**
     * Activates the camera to take a selfie.
     */
    takeSelfie(isBack = false) {
      this.startCamera = true;
      this.isBackSide = isBack; // Add this to track which side is being captured
    },

    /**
     * Retrieves the image data after taking a selfie.
     * @param {Object} data - The image data containing base64Image and file.
     */
    getImage(data) {
      if (this.isBackSide) {
        this.backImageSrc = data.base64Image;
        this.backCameraBlob = data.file;
      } else {
        this.imageSrc = data.base64Image;
        this.selfieCameraBlob = data.file;
      }
      this.showRetry = true;
    },
    /**
     * @Description
     *  Retrieves the client secret and remaining document OCR count from the server.
     * @param none
     * @return none
     **/
    getClientSecret() {
      this.isLoading = true;
      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          this.clientSecret = res.data.result.data.client_secret;
          this.isLoading = false;
          // ApiService.PostRequest(
          //   "document-ocr-count",
          //   {},
          //   "iframe",
          //   this.clientSecret
          // )
          // .then(() => {
          //   // this.remainingCount = response.data.result.data.document_count;
          //   this.isLoading = false;
          // })
          // .catch((error) => {
          //   showErrorMessage(error);
          //   this.isLoading = false;
          // });
        })
        .catch((error) => {
          showErrorMessage(error);
          this.isLoading = false;
        });
    },
    /**
     * @Description Submits data for processing.
     * Initiates a series of API requests to process the submitted image data and retrieve results.
     * @param none
     * @return none
     **/
    submitData() {
      this.$store.dispatch("setOcrResult");
      this.$store.dispatch("clearDeclineReason");
      this.showRetry = false;
      this.isProcessing = true;
      this.resultLoader = true; // Show loader when starting the request

      let data = {
        type: "document_verification",
        file: this.imageSrc,
        additional_file: this.backImageSrc,
      };
      ApiService.PostRequest(
        "document-verification",
        data,
        "iframe",
        this.clientSecret
      )
        .then((res) => {
          this.apiCallMade = true; // Set this flag when API call completes
          // Check if OCR results are available
          if (res.data.result && res.data.result.data.ocr_results) {
            // Store the OCR results
            this.jsonData = res.data.result.data.ocr_results;
            const resultOcr = res.data.result.data.event;
            if (resultOcr) {
              //Store the ocr_result in store
              this.$store.dispatch("setOcrResult", resultOcr);
            }
            // Only set decline reason if it exists in the response
            const declineReason = res.data.result.data.decline_reason;
            if (declineReason) {
              this.$store.dispatch("setDeclineReason", declineReason);
            }
            // Hide the loader after receiving the result
            this.resultLoader = false;
            this.isProcessing = false;
            this.hideSubmit = false;
          } else {
            // Handle missing results
            this.handleMissingResults(); // Optionally handle cases where result is missing
          }
        })
        .catch((error) => {
          this.$store.dispatch("clearDeclineReason"); // Clear on error
          this.$store.dispatch("setOcrResult");
          window.location.reload();
          this.apiCallMade = true; // Set this flag even on error
          // Handle errors
          showErrorMessage(error);
          // Stop loader and reset processing flags
          this.resultLoader = false;
          this.isProcessing = false;
          if (error.response && error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },
    /**
     * @Description Handle missing results scenario.
     * Hide loader and show a message or retry.
     **/
    handleMissingResults() {
      this.resultLoader = false; // Ensure loader is hidden when no results are available
      this.isProcessing = false;
      showSimpleErrorMessage("Unable to Extract the Data.");
    },
    /**
     * Reloads the window to retry taking a selfie.
     */
    retry() {
      this.apiCallMade = false; // Reset the flag
      this.$store.dispatch("setOcrResult"); // Reset the OCR result
      this.$store.dispatch("clearDeclineReason");
      window.location.reload();
    },

    /**
     * Closes the camera interface.
     */
    closeCamera() {
      this.startCamera = false;
    },

    /**
     * @Description
     * 1. this method is used to initialize the browse click
     * @param none
     * @return none
     **/
    browseClick(isBack = false) {
      if (isBack) {
        this.$refs["back-file-uploader"].click(); // Back file upload
      } else {
        this.$refs["file-uploader"].click(); // Front file upload
      }
    },

    /**
     * @Description
     * This action is used to assign a file to imageSrc / videoSrc variable
     *  **/
    // Handles the file selection for front or back image
    onFileChanged(event, isBack = false) {
      const file = event.target.files[0];
      // Clear previous image if no file is selected
      if (!file) {
        if (isBack) {
          this.backImageSrc = null;
        } else {
          this.imageSrc = null;
        }
        return;
      }
      if (file && file.size <= this.fileSizeLimit) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (isBack) {
            this.backImageSrc = e.target.result; // Set the back image
          } else {
            this.imageSrc = e.target.result; // Set the front image
          }
          this.showRetry = true;
        };
        reader.readAsDataURL(file);
      } else {
        // Reset the file input
        if (isBack) {
          this.$refs["back-file-uploader"].value = "";
        } else {
          this.$refs["file-uploader"].value = "";
        }
        showErrorMessage("File size exceeds limit or invalid file type.");
      }
    },
    retryImage(isBack = false) {
      if (isBack) {
        this.backImageSrc = null;
        if (this.$refs["back-file-uploader"]) {
          this.$refs["back-file-uploader"].value = "";
        }
      } else {
        this.imageSrc = null;
        if (this.$refs["file-uploader"]) {
          this.$refs["file-uploader"].value = "";
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.searchResultInterval);
    clearTimeout(this.setTimeoutInterval);
    this.$store.dispatch("clearDeclineReason");
    this.$store.dispatch("setOcrResult");
  },
};
</script>

<style scoped>
.container {
  margin-top: 2rem;
}
.main-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 10px;
}
.sub-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 10px;
}
.sub-heading.no-padding {
  padding-left: 0; /* Override padding */
}
.age-main-container {
  padding: 40px;
}
.age-alert {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fafafa;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.age-alert:deep(.v-alert__wrapper) {
  align-items: start;
}
.age-alert:deep(.v-alert__content) {
  text-align: justify;
  padding-right: 20px;
}
.age-verification-card {
  height: 300px;
  padding: 0px 16px 16px 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.age-verification-card:deep(.v-card__text) {
  padding: 10px;
}
.ocr-result-card {
  max-height: 450px;
  overflow-y: scroll;
  border-radius: 10px;
  background: #f7f8f9;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.09),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.loader-div {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.card-title {
  display: flex;
  justify-content: center;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding: 4px 16px 4px 16px;
}
.card-body {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  overflow: hidden;
}
.card-body-mobile {
  height: auto;
}
.card-footer {
  display: flex;
  justify-content: center;
  color: #909295;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.file-limit-text {
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.vertical-divider {
  width: 5px;
  height: 20px;
  min-height: 20px;
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  align-self: end;
}
.option {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
}
.option-child {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 5px;
  cursor: pointer;
}
.option-text {
  color: #213cc8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.substr-text {
  color: #213cc8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0 2px;
}
.preview-img {
  width: 100%;
  max-height: 210px;
  object-fit: contain;
  aspect-ratio: 16/9;
  border-radius: 6px;
}
.preview-img-mobile {
  width: 250px;
}
.result-btns {
  column-gap: 16px;
}
.result-btns .view-result {
  display: flex;
  column-gap: 4px;
  color: #292b30;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.result-btns .try-more {
  display: flex;
  column-gap: 4px;
  width: 110px;
  height: 32px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.main-heading:deep(.info-icon) {
  font-size: 16px;
}
.selfie-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.step-retry {
  width: 100%;
  display: flex;
  justify-content: end;
}
.card-retry {
  color: #213cc8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.no-record {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-type {
  position: absolute;
  bottom: 18px;
}
.button-section {
  display: flex;
  justify-content: end;
  align-items: baseline;
}
.copy-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
</style>

<style>
.menu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.menu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.menu-custom-class .v-list-item__action {
  margin: 0px;
}

.menu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}

.search-filters fieldset {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.search-filters .v-input__slot {
  min-height: 30px !important;
}

.search-filters .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
  transform: none !important;
}

.search-filters .v-icon::before {
  color: #a0a6b4 !important;
}

.search-filters .mdi-chart-line.v-icon {
  font-size: 15px !important;
}
</style>
